@use '@angular/material' as mat;
$c-form-part-max-width-lg: 100%;
$c-form-part-max-width-md: 100%;

@import '@angular/material/theming';
@include mat.core();
$candy-app-primary: mat.define-palette(mat.$indigo-palette);
$candy-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$candy-app-warn: mat.define-palette(mat.$red-palette);
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
@include mat.all-component-themes($candy-app-theme);
@import '@pushdr/stylekit/scss/stylekit.scss';

.material-icons {
  vertical-align: top;
}

// overrides
$listview-edit-icon-size: 20px;

.c-btn {
  max-width: 100%;
}

.c-address-listview {
  max-height: 400px;
  overflow-y: auto;
}

.c-listview {
  padding-left: 0;
}

.c-listview__item {
  padding-left: 0;
  margin-bottom: 0;
  padding-top: $base-spacing-unit--small;
  border-bottom: 1px solid theme-color(grey-200);
  &:before {
    display: none;
  }

  &:first-child {
    border-top: 1px solid theme-color(grey-200);
  }

  > h2,
  > p {
    padding-left: $base-spacing-unit--tiny;
  }
}

.c-upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-flex;

  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 100px;
    opacity: 0;
  }
}

.c-invoice {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 12px;
}

.c-listview__item--editable {
  position: relative;
  padding-right: $base-spacing-unit--large * 2;

  &:before {
    content: 'edit';
    display: inline-block;
    position: absolute;
    right: $base-spacing-unit--large - 4px;
    top: 38%;
    left: auto;
    font-size: $base-font-size !important;
  }

  &:after {
    content: '';
    position: absolute;
    background-image: url(/assets/icons/back-arrow-black.svg);
    background-repeat: no-repeat;
    transform: rotate(-90deg);
    right: $base-spacing-unit--tiny;
    top: 41%;
    background-size: contain;
    height: $listview-edit-icon-size - 8px;
    width: $listview-edit-icon-size;
  }

  &:hover {
    cursor: pointer;
    background-color: theme-color(grey-300);
    transition: background-color 300ms ease-out;
  }
  transition: background-color 300ms ease-in;
}

.c-listview__item--selectable {
  position: relative;
  padding-right: $base-spacing-unit--large * 2;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    right: $base-spacing-unit--large - 4px;
    top: 38%;
    left: auto;
    font-size: $base-font-size !important;
  }

  &:after {
    content: '';
    position: absolute;
    background-image: url(/assets/icons/back-arrow-black.svg);
    background-repeat: no-repeat;
    transform: rotate(-90deg);
    right: $base-spacing-unit--tiny;
    top: 41%;
    background-size: contain;
    height: $listview-edit-icon-size - 8px;
    width: $listview-edit-icon-size;
  }

  &:hover {
    cursor: pointer;
    background-color: theme-color(grey-300);
    transition: background-color 300ms ease-out;
  }
  transition: background-color 300ms ease-in;
}

.c-listview__heading {
  @extend p;
  font-weight: $font-weight-normal;
  margin-bottom: $base-spacing-unit--teeny;
}

/*
c-hero
*/
.c-hero {
  position: relative;

  img {
    width: 100%;
  }
}

.c-jumbotron {
  background: theme-color(grey-300);
  border-radius: $c-form-part-border-radius;
  padding: $base-spacing-unit--tiny;
  margin: $base-spacing-unit--tiny 0;

  button.c-btn.c-btn--primary {
    margin-bottom: 0;
  }

  h5 {
    margin: $base-spacing-unit--tiny 0;
  }
}

.c-btn-app-back {
  border: none;
  outline: none;
  position: relative;
  background-color: transparent;
  display: block;
  margin-bottom: $base-spacing-unit--small;
  cursor: pointer;

  &:before {
    content: '';
    display: inline-block;
    position: relative;
    background-image: url(/assets/images/back-arrow.svg);
    background-repeat: no-repeat;
    transform: rotate(90deg);
    width: $base-spacing-unit--tiny;
    height: $base-spacing-unit--tiny;
    vertical-align: middle;
  }
}

.c-form-part__field-wrap .c-form-part__field {
  border-color: #b3b3b3 #ccc #d9d9d9;
}

.c-form-part__two-date-field-wrap {
  border-radius: 3px;
  position: relative;
  border: 1px solid #000000;
  max-width: 230px;
  margin-top: 8px;
  margin-right: 20px;

  &:after {
    content: '/';
    font-size: 17px;
    position: absolute;
    bottom: 16px;
    color: #57575c;
    opacity: 0.8;
    left: 50%;
  }

  .c-form-part__two-field.c-form-part__two-field-1 {
    padding-right: 5px;
    padding-left: 5px;
    max-width: 70px;
  }

  .c-form-part__two-field.c-form-part__two-field-2 {
    padding-right: 5px;
    padding-left: 10px;
    max-width: 70px;
  }

  .c-form-part__two-field {
    border: none;
    display: inline-block;
    background-color: transparent;
    margin: 0;
    text-align: center;
  }
}

// __<<ngThemingMigrationEscapedComment1>>__

select {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  display: block;
  width: 100%;
  border: 1px solid #31393c;
  padding: 16px;
  margin: 16px 0 24px;
  color: #31393c;
  -webkit-transition: border 0.2s ease-out;
  transition: border 0.2s ease-out;
  font-size: 17px;
  position: relative;
}

.c-form-part__entry--is-invalid {
  select {
    border-color: theme-color(quaternary);
  }
}

// __<<ngThemingMigrationEscapedComment2>>__

table.pp-table {
  width: 100%;
  border-spacing: 0 1px;
  font-weight: 300;
  color: theme-color(grey-100);

  .c-btn {
    margin: 10px 0;
  }

  td:first-child,
  td:first-child p {
    font-weight: 400;
  }

  td,
  th {
    padding: 10px;
    text-align: left;
  }

  tr:nth-child(odd) {
    background-color: #f4f8ff;
  }
}

.table-searchBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  input {
    max-width: 320px;
    margin: 0;
  }
  .c-btn {
    margin: 0;
  }
}

// patch
.modal__footer {
  margin-top: 24px;
}

.c-form-part__three-date-field-wrap,
.c-form-part__three-date-field-wrap {
  &:before,
  &:after {
    content: none;
  }
}

.show-validation {
  input,
  textarea {
    &.ng-invalid.ng-touched {
      @apply border-error;
      .ng-select.ng-select-single > .ng-select-container {
        @apply border-error;
      }
    }
    &.ng-untouched,
    &.ng-valid {
      & + .text-error {
        display: none;
      }
    }
    & ~ .text-error {
      display: block;
      @apply mt-2;
      & ~ .text-error {
        @apply mt-0;
      }
    }
  }
}
